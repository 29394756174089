@font-face {
  font-family: 'Trade Gothic LT W01 Bd Cn No-2';
  src: url('../src/assets/fonts/227de6ba-fef9-435d-8693-a53a76aa1add.eot?#iefix');
  src: url('../src/assets/fonts/227de6ba-fef9-435d-8693-a53a76aa1add.eot?#iefix') format('eot'),
    url('../src/assets/fonts/dae937ec-159f-4710-9b2d-9470e24807ba.woff2') format('woff2'),
    url('../src/assets/fonts/c8856bf2-4d23-4e30-9fb1-eede15b787b6.woff') format('woff'),
    url('../src/assets/fonts/376f433f-1486-4168-932f-06352c8291f8.ttf') format('truetype');
  font-display: swap;
}

html,
body {
  min-height: 100%;
}

html {
  background-color: #0072ce;
  height: 100%;
}

body {
  background-image: linear-gradient(180deg, #0072ce 47.56%, #00e7ef 120.44%);
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: overlay;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  /* We use custom styles for a links, so we want to override the default */
  text-decoration: none;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: rgb(200, 200, 200, 0.4);
  border-radius: 10px;
  opacity: 0.9;
  display: block;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(200, 200, 200, 0.6);
  border-radius: 10px;
  opacity: 0.9;
  display: block;
}

.videoSkeleton {
  height: 318px;
}

/* To be added to Storybook */
.rebrand-button {
  background-color: #252b5a;
  display: flex;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transform: skew(-7deg);
  border-radius: 0;
  /* text */
  color: #fff;
  font-family: 'Barlow Condensed';
  font-size: 15px;
  font-weight: 700;
  line-height: 120%; /* 18px */
}

.rebrand-button.outlined {
  background-color: white;
  color: #252b5a;
  border: 2px #252b5a solid;
  padding: 8px 13px;
}

.rebrand-button:hover {
  background-color: #252b5a;
}

.rebrand-button.outlined:hover {
  background-color: white;
}

.video-resource-hub .page-container {
  border-radius: 0;
  padding: 40px;
}

@media (max-width: 900px) {
  .video-resource-hub .page-container {
    padding: 20px;
  }
}

@media (max-width: 1199px) {
  .videoSkeleton {
    height: 169px;
  }
}

@media (max-width: 600px) {
  .MuiAlert-icon {
    padding: 5px 0px;
    margin-right: 5px;
  }

  .MuiAlert-root {
    padding: 6px 10px;
  }
}
