.outdated-container {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.outdated-text-content {
  overflow-wrap: break-word;
}

.outdated-text-content > h1 {
  font-size: 52px;
  line-height: 52px;
  color: white;
  font-weight: 700;
  font-family: 'Trade Gothic LT W01 Bd Cn No-2';
  text-transform: uppercase;
  margin: 0;
}

.outdated-text-content > h3 {
  font-size: 30px;
  line-height: 30px;
  color: white;
  font-weight: 700;
  font-family: 'Trade Gothic LT W01 Bd Cn No-2';
  text-transform: uppercase;
  margin: 0;
}

.outdated-text-content > h5 {
  font-size: 20px;
  line-height: 28px;
  color: white;
  font-weight: 700;
  letter-spacing: 0;
  font-family: 'Roboto';
  margin: 0;
}

.outdated-text-content > p {
  font-size: 14px;
  line-height: 20px;
  color: white;
  font-weight: 700;
  letter-spacing: 0;
  font-family: 'Roboto';
  margin: 0;
}

.outdated-text-content > p > a {
  font-size: 14px;
  line-height: 20px;
  color: white;
  font-weight: 700;
  letter-spacing: 0;
  font-family: 'Roboto';
  margin: 0;
}
